<template>
  <div>
    <v-card>
      <v-card-text>
        <Account />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Account from './account/index';

export default {
  components: {
    Account
  },
  data: () => ({
    tab: 0,
  }),
  created() {
    this.tab = this.$route.params.tab || 0;
  },
};
</script>
