<template>
  <v-row class="mt-4 mt-sm-0">
    <v-col cols="12" sm="3">
      <v-tabs v-model="tab" :vertical="!$vuetify.breakpoint.xs" :show-arrows="$vuetify.breakpoint.xs">
        <v-tab class="text-none justify-start">General</v-tab>
        <v-tab class="text-none justify-start">Change password</v-tab>
      </v-tabs>
    </v-col>
    <v-col cols="12" sm="9">
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <GeneralSettings />
        </v-tab-item>

        <v-tab-item>
          <ChangePassword />
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import GeneralSettings from './general-settings';
import ChangePassword from './change-password';
// import NotificationSettings from './notification-settings';

export default {
  components: {
    GeneralSettings,
    ChangePassword,
    // NotificationSettings,
  },
  data: () => ({
    tab: 0,
  }),
};
</script>
